@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    transition: 0.3s;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: century-gothic, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.image-line {
  position: absolute;
  width: 2px;
  height: 45vw;
  background: #00ffff;
  right: 5%;
  top: -25px;
}

.list-disc {
  padding-left: 1.5em;
}

.about-items li a::after {
  content: "";
  width: 15px;
  height: 15px;
  right: -48px;
  border-radius: 50%;
  border: 1px solid #1E1E1E;
  position:absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
  transition: 0.3s;
}

.dark .about-items li a::after {
  border: 1px solid #fff;
  background: #1E1E1E;
}


.about-items li a.visible::after {
  background: #ff00ff;
  border-color: #ff00ff;
}
.about-items li a.visible {
  color: #ff00ff;
  font-weight: 700;
}

.menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
}
.line {
  fill: none;
  stroke: black;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}


::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-background);
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
    transition: 0.3s;
  }
  
  ::-webkit-scrollbar-track {
    background-color: var(--scrolltrack-background);
    transition: 0.3s;
  }
  
  ::-webkit-scrollbar {
    width: 20px;
  }

  .visible .tracking-in-expand {
    -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
            animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  }

  .visible .scale-in  {
    -webkit-animation: scale-in 0.4s ease-out forwards;
            animation: scale-in 0.4s ease-out forwards;
  }

  @-webkit-keyframes scale-in {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1)
    }
  }

  @keyframes scale-in {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1)
    }
  }

  @-webkit-keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }

  .visible .fade-in-bottom {
    -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation-delay: 1s;
  }

  @-webkit-keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
              transform: translateY(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
              transform: translateY(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }



  .visible .text-focus-in {
    -webkit-animation: text-focus-in .8s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
            animation: text-focus-in .8s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

  @-webkit-keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  


  /*Border Draw*/
  .border-draw {
    animation: border-draw-reverse 1.5s cubic-bezier(0.19, 1, 0.22, 1); 
    background-image: linear-gradient(to right, #ff00ff 100%, #ff00ff 100%), linear-gradient(to bottom, #ff00ff 100%, #ff00ff 100%),   linear-gradient(to right, #00ffff 100%, #00ffff 100%), linear-gradient(to bottom, #00ffff 100%, #00ffff 100%);
    background-position: 0 0, 100% 0,  100% 100%, 0 100%;
    background-size: 100% 6px, 6px 100%, 100% 6px, 6px 100%;
    background-repeat: no-repeat; 
    animation-fill-mode: forwards
  }

  .visible .border-draw {
    animation: border-draw 1.5s cubic-bezier(0.19, 1, 0.22, 1) 1; 
    background-image: linear-gradient(to right, #ff00ff 100%, #ff00ff 100%), linear-gradient(to bottom, #ff00ff 100%, #ff00ff 100%),   linear-gradient(to right, #00ffff 100%, #00ffff 100%), linear-gradient(to bottom, #00ffff 100%, #00ffff 100%);
    background-position: 0 0, 100% 0,  100% 100%, 0 100%;
    background-size: 100% 6px, 6px 100%, 100% 6px, 6px 100%;
    background-repeat: no-repeat;
  }

  @keyframes border-draw {
    0% {
        background-size:    0 6px, 6px 0, 0 6px, 6px 0;
    }
    25% {
        background-size:    100% 6px, 6px 0, 0 6px, 6px 0;
    }
    50% {
        background-size:    100% 6px, 6px 100%, 0 6px, 6px 0;
    }
    75% {
        background-size:    100% 6px, 6px 100%, 100% 6px, 6px 0;
    }
    100% {
        background-size:    100% 6px, 6px 100%, 100% 6px, 6px 100%;
    }
}

@keyframes border-draw-reverse {
  0% {
    background-size:    100% 6px, 6px 100%, 100% 6px, 6px 100%;
  }
  25% {
    background-size:    100% 6px, 6px 100%, 100% 6px, 6px 0;
  }
  50% {
    background-size:    100% 6px, 6px 100%, 0 6px, 6px 0;
  }
  75% {
    background-size:    100% 6px, 6px 0, 0 6px, 6px 0;
  }
  100% {
    background-size:    0 6px, 6px 0, 0 6px, 6px 0;
  }
}
  

@media only screen and (max-width: 976px) {
.foam {
    background-size: 164vh 250vw;
}
}

#site {
  min-height: 100vh;
}




.fadeIn .page {
  animation: 0.5s fadeIn forwards;
}

.fadeOut .page{
  animation: 0.5s fadeOut forwards;

}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(-50px, 0);
    opacity: 0;
  }
}

.visible .slide-in-right {
	-webkit-animation: slide-in-right 0.5s ease-out both;
	        animation: slide-in-right 0.5s ease-out both;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(500px);
            transform: translateX(500px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}


.visible .expand-line {
	-webkit-animation: expand-line 1s ease-in-out both;
	        animation: expand-line 1s ease-in-out both;
          animation-delay: 0.7s;
}

@keyframes expand-line {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 45vw;
    opacity: 1;
  }
}
